import React from 'react'
import styled from 'styled-components'
import Img from "gatsby-image"

export default props => <Sponser>
  <a href={props.url}　target="_blank" rel="noopener noreferrer">
    <Image fluid={props.fluid} />
  </a>
</Sponser>


const Sponser = styled.div`
  position: relative;
  height:150px;
  margin:1em;
`

const Image = styled(Img)`
  max-width:100%;
  @media only screen and (max-width: 768px) and (min-width: 500px){
    width:50%;
  }
  max-height:150px;
  position: absolute;
  top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	margin: auto;
`

import React from 'react'
import styled from 'styled-components'
import Img from "gatsby-image"
import {OnlyPC,OnlySP} from '../components/responsive/'

export default props => <div>
  <OnlyPC>
    <SponserPC>
      <SponserInnerPC>
        <a href={props.url}　target="_blank" rel="noopener noreferrer">
            <Img fixed={props.fixed400} />
        </a>
      </SponserInnerPC>
    </SponserPC>
  </OnlyPC>
  <OnlySP>
    <SponserSP>
      <SponserInnerSP>
        <a href={props.url}　target="_blank" rel="noopener noreferrer">
            <Img fixed={props.fixed300} />
        </a>
      </SponserInnerSP>
    </SponserSP>
  </OnlySP>
</div>


const SponserPC = styled.div`
  position: relative;
  height:184px;
  margin:1em;
`

const SponserSP = styled.div`
  position: relative;
  height:138px;
  margin:1em;
`

const SponserInnerPC = styled.div`
  width:400px;
  height:184px;
  position: absolute;
  top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	margin: auto;
`

const SponserInnerSP = styled.div`
  width:300px;
  height:138px;
  position: absolute;
  top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	margin: auto;
`

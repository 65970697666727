import React from 'react'
import { graphql } from 'gatsby'
import styled from 'styled-components'
import { Container, Row, Grid, Inner, OnlyPC, OnlySP} from '../components/responsive/'
import H1 from '../components/h1'
import H2 from '../components/h2'
import Banner from '../components/banner'
import Content from '../components/content'
import ContentHTML from '../components/contentHTML'
import Image from '../components/image'
import SpeakerImg from '../components/speakerImg'
import Sponser from '../components/sponser'
import MediaSponser from '../components/mediaSponser'
import ContainerFooter from '../components/containerFooter'
import Footer from '../components/footer/footer'
import Header from '../components/header/header'
import Entry from '../components/entry'
import Metadata from '../components/metadata'
import './index.css'

export default ({ data }) => (
      <div>
      <Metadata/>
      <Header active='home'/>
      <Banner imgPC={data.topPC.childImageSharp.fixed} imgSP={data.topSP.childImageSharp.fixed}/>
      <Container backgroundColor='#FFF' paddingTop='1em'>
      <H1>
      2018年11月17日、東京 羽田で<PCbr/>
      Drupal Camp DENを開催。
      </H1>
      <Inner
        size='2em'
        as='h2'
        color='#7f7f7f'
        marginTop='1em'
        marginBottom='1em'
        lineHeight='1.5em'
        center>
      日本のカスタマーエクスペリエンス、<PCbr/>
      デジタルエクスペリエンスを促進させる、<PCbr/>
      Drupalを体験する1日です。
      </Inner>
      <Entry/>
      <Content margin='2em 0 1em 0'>
      DrupalはCX(カスタマーエクスペリエンス)・DX(デジタルエクスペリエンス)のプラットフォームとして世界中で支持されています。Drupalの日本国内のオープンソースコミュニティの更なる育成および活動の活性化とDrupalの知名度の向上のため、Drupalを活用している企業や経営者、マーケター、エンジニア、ディレクター、デザイナーなど、様々な職種の方々を招いて、Drupalの活用、CX、DXについて情報を交換し、日本でのDrupal活用を盛り上げていきます。
      </Content>
      <Inner
        size='2em'
        as='h4'
        color='#0098cb'
        marginTop='2em'
        marginBottom='1em'>
      開催概要
      </Inner>
      <Row>
        <Grid pc='7' sp='12'>
          <Row>
            <Grid pc='3' sp='4'>
              <Inner bold color='#7f7f7f' as='span'>
                開催日時
              </Inner>
            </Grid>
            <Grid pc='9' sp='8'>
              <Inner color='#7f7f7f' as='span'>
                2018年11月17日(土曜日)
              </Inner>
            </Grid>
            <Grid pc='3' sp='4' as='span'>
              <Inner bold marginTop='1em' color='#7f7f7f'>
                開催場所
              </Inner>
            </Grid>
            <Grid pc='9' sp='8' as='span'>
              <Inner marginTop='1em' color='#7f7f7f'>
                <p>大田区産業プラザPio 2F biz BEACH CoWorking<br/>
Pio：A会議室 B会議室</p>
<p>
東京都大田区南蒲田1-20-20<br/>
■京急蒲田駅から、徒歩約3分<br/>
■JR蒲田駅から、徒歩約10分</p>
              </Inner>
            </Grid>
            <Grid pc='3' sp='4' as='span'>
              <Inner bold marginTop='1em' color='#7f7f7f'>
                参加人数
              </Inner>
            </Grid>
            <Grid pc='9' sp='8' as='span'>
              <Inner marginTop='1em' color='#7f7f7f'>
                100名
              </Inner>
            </Grid>
            <Grid pc='3' sp='4' as='span'>
              <Inner bold marginTop='1em' color='#7f7f7f'>
                参加費用
              </Inner>
            </Grid>
            <Grid pc='9' sp='8' as='span'>
              <Inner marginTop='1em' color='#7f7f7f'>
                無料
              </Inner>
            </Grid>
            <Grid pc='3' sp='4' as='span'>
              <Inner bold marginTop='1em' color='#7f7f7f'>
                主催
              </Inner>
            </Grid>
            <Grid pc='9' sp='8' as='span'>
              <Inner marginTop='1em' marginBottom='1em' color='#7f7f7f'>
                DEN(Drupal Experience Network)
              </Inner>
            </Grid>
            <Grid pc='3' sp='4' as='span'>
              <Inner bold marginTop='1em' color='#7f7f7f'>
                後援
              </Inner>
            </Grid>
            <Grid pc='9' sp='8' as='span'>
              <Inner marginTop='1em' marginBottom='1em' color='#7f7f7f'>
                <a href='https://www.pio-ota.jp/' target='_blank' rel="noopener noreferrer">公益財団法人大田区産業振興協会</a><br/>
              </Inner>
              <Inner marginTop='1em' marginBottom='1em' color='#7f7f7f'>
                <a href='https://biz BEACH.jp/' target='_blank' rel="noopener noreferrer">biz BEACH CoWorking</a><br/>
              </Inner>
            </Grid>
          </Row>
        </Grid>
        <Grid pc='5' sp='12'>
          <OnlyPC>
          <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3245.795037859643!2d139.72196261546998!3d35.558763844285544!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x601860f87f5da4e3%3A0x8a0493a2f4accfb0!2z5aSn55Sw5Yy655Sj5qWt44OX44Op44K2UGlP!5e0!3m2!1sja!2sjp!4v1538199584242" width="100%" height="450" frameBorder="0" allowFullScreen title='map1'></iframe>
          </OnlyPC>
          <OnlySP>
          <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3245.795037859643!2d139.72196261546998!3d35.558763844285544!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x601860f87f5da4e3%3A0x8a0493a2f4accfb0!2z5aSn55Sw5Yy655Sj5qWt44OX44Op44K2UGlP!5e0!3m2!1sja!2sjp!4v1538199584242" width="100%" height="250" frameBorder="0" allowFullScreen title='map2'></iframe>
          </OnlySP>
        </Grid>
      </Row>
      <Inner
        size='2em'
        as='h4'
        color='#0098cb'
        marginTop='1em'
        marginBottom='1em'>
      参加対象
      </Inner>
      <Grid pc='6' sp='12'>
        <Content>
        IT関連企業様、広報様、マーケティング部門の責任者様・担当者様、IT関連制作会社様・運営会社様・管理会社様、メディア関係各位様、Eコマース関係者様
        </Content>
      </Grid>
      <H2>
      KEYNOTE SPEAKERS
      </H2>
      <Row>
        <Grid pc='3' sp='8' >
          <SpeakerImg fluid={data.keyspeaker.childImageSharp.fluid}/>
        </Grid>
        <Grid pc='0' sp='12'>
        <OnlySP>
        <Inner margin='1em'>
        </Inner>
        </OnlySP>
        </Grid>
        <Grid pc='9' sp='12'>
          <Inner size='2em' color='#7f7f7f'>
            {data.camp2018.rest.contents.field_keynote_speaker_name}
          </Inner>
          <Content>
            {data.camp2018.rest.contents.field_keynote_speaker_title}
          </Content>
          <ContentHTML>
            <div dangerouslySetInnerHTML={{__html: data.camp2018.rest.contents.field_keynote_speaker_desc}}/>
          </ContentHTML>
        </Grid>
      </Row>
      <H2>
      スポンサー
      </H2>
      <Row>
        { data.camp2018.rest.sponsers.map((sponser, key) => {
          return <Grid key={key} pc='4' pcOffset={key % 2 === 0 ? '2' : '0'} sp='12'>
                    <Sponser fluid={data.sponsers.edges.filter(edge => sponser.fileName === edge.node.childImageSharp.fluid.originalName)[0].node.childImageSharp.fluid}
                      url={sponser.field_sponser_url}/>
                  </Grid>
        })}

      </Row>
      <H2>
      メディアスポンサー
      </H2>
      <MediaSponser
        fixed400={data.atit400.childImageSharp.fixed}
        fixed300={data.atit300.childImageSharp.fixed}
        url='http://www.atmarkit.co.jp/'/>
      <H2>
      ビデオ視聴
      </H2>
      <Row>
        <Grid pc='3' sp='12'>
          <Image fluid={data.video.childImageSharp.fluid}/>
        </Grid>
        <Grid pc='8' pcOffset='0.5' sp='12'>
          <ContentHTML>
            <div dangerouslySetInnerHTML={{__html: data.camp2018.rest.contents.field_video_description}}/>
          </ContentHTML>
        </Grid>
      </Row>
      <H2>
      スケジュール
      </H2>
      <ContentHTML>
        <div dangerouslySetInnerHTML={{__html: data.camp2018.rest.contents.field_schedule}}/>
      </ContentHTML>
      <ContainerFooter/>
      </Container>
      <Footer/>
      </div>
)

export const query = graphql`
  query {
    topPC:file(relativePath: { eq: "Drupal_camp_Banner.png" }) {
      childImageSharp {
        fixed(width: 1140, quality: 100) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    topSP:file(relativePath: { eq: "Drupal_camp_Banner.png" }) {
      childImageSharp {
        fixed(height: 300, quality: 100) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    keyspeaker:file(relativePath: { eq: "keyspeaker.jpeg" }) {
      childImageSharp {
        fluid(maxWidth: 1200, quality: 100) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    video:file(relativePath: { eq: "TOP1.png" }) {
      childImageSharp {
        fluid(maxWidth: 1200, quality: 100) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    map:file(relativePath: { eq: "map.png" }) {
      childImageSharp {
        fluid(maxWidth: 1200, quality: 100) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    atit400:file(relativePath: { eq: "atmarkit_w400.png" }) {
      childImageSharp {
        fixed(width: 400, quality: 100) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    atit300:file(relativePath: { eq: "atmarkit_w400.png" }) {
      childImageSharp {
        fixed(width: 300, quality: 100) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    sponsers:allFile(filter:{relativePath:{regex:"/^sponser/"}}){
      edges{
        node{
          childImageSharp {
            fluid{
              ...GatsbyImageSharpFluid_noBase64
              originalName
            }
          }
        }
      }
    }
    camp2018{
      rest{
        contents{
          field_keynote_speaker_title,
          field_keynote_speaker_name,
          field_keynote_speaker_desc,
          field_schedule,
          field_video_description
        }
        sponsers {
          field_sponser_url
          field_sponser_logo
          field_sponser_number
          fileName
        }
      }
    }
  }
`


const PCbr = styled.br`
  @media only screen and (max-width: 768px) {
    display: none;
  }
`

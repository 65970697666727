import React from 'react'
import {Inner} from '../components/responsive/'
import Img from "gatsby-image"
import styled from 'styled-components'

export default props => <Inner marginLeft='1em' marginRight='1em'>
  <Image fluid={props.fluid} />
</Inner>

const Image = styled(Img)`
  max-width:200px;
`

import React from 'react'
import styled from 'styled-components'
import size from './responsive/size'
import Img from "gatsby-image"
import {OnlyPC,OnlySP} from '../components/responsive/'

export default props => <Wrap>
<Banner>
  <OnlyPC>
    <Img fixed={props.imgPC} style={{width:'1200px'}}/>
  </OnlyPC>
  <OnlySP>
    <Img fixed={props.imgSP} style={{height:'300px'}}/>
  </OnlySP>
</Banner>
</Wrap>

const Wrap = styled.div`
  overflow: hidden;
`
const Banner = styled.div`
  margin-top:2em;
  background-repeat: no-repeat;
  height:450px;
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
  background-size:1200px auto;
  @media only screen and (max-width: ${size.sp}px) {
    margin-top:4.5em;
    height:300px;
    background-size:${size.sp}px auto;
  }
`

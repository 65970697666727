import React from 'react'
import {Inner} from '../components/responsive/'

export default props => <Inner
  size='2em'
  as='h2'
  color='#7f7f7f'
  borderTop
  borderBottom
  marginTop='1em'
  marginBottom='1em'
  paddingTop='0.5em'
  paddingBottom='0.5em'
  borderColor='#0098cb'>
{props.children}
</Inner>
